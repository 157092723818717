<template>
  <div class="top-padding">
    <top-home :title="type=='xtgg'?'公告':'消息'"></top-home>
    <div v-if="type=='xtgg'">
      <div
        class="notice-list"
        :class="{'empty-list':sysList.length<=0}"
      >
        <div
          class="list-item"
          v-for="(item,index) in sysList"
          :key="index"
        >
          <div
            class="item-title ellipsis"
            v-if="item.ggbt"
          >{{item.ggbt}}</div>
          <div
            class="item-text item-content info-content"
            v-if="item.nr"
            v-html="item.nr"
          ></div>
          <div
            v-if="item.gglx"
            style="width: 100%;box-sizing: border-box;text-align: right;font-size: 12px;padding: 8px;"
          >{{item.gglx}} | {{item.create_time}}</div>
          <div class="item-btn">
            <span @click="$router.push({path:'/noticeDetails',query:{id:item.id,type:'xtgg'}})">查看详情>></span>
          </div>
        </div>
        <div
          class="list-empty"
          v-if="sysList.length<=0"
        >暂无其他公告</div>
      </div>
      <div
        class="empty-box"
        v-if="sysList.length<=0"
      >
        <img
          src="../../assets/PersonalCenter/PointsCenter/noDataPng.png"
          alt
          width="80%"
        />
        <div class="empty">暂无数据</div>
      </div>
    </div>
    <div v-else>
      <div
        class="notice-list"
        :class="{'empty-list':smsList.length<=0}"
      >
        <div
          class="list-item"
          v-for="(item,index) in smsList"
          :key="index"
        >
          <div
            class="item-text item-content info-content"
            v-if="item.xxnr"
            v-html="item.xxnr"
          ></div>
          <div
            v-if="item.xxlx"
            style="width: 100%;box-sizing: border-box;text-align: right;font-size: 12px;padding: 8px;"
          >{{item.xxlx}} | {{item.fssj}}</div>
          <div class="item-btn">
            <span @click="$router.push({path:'/noticeDetails',query:{id:item.id,type:'tsxx'}})">查看详情>></span>
          </div>
        </div>
        <div
          class="list-empty"
          v-if="smsList.length<=0"
        >暂无其他消息</div>
      </div>
      <div
        class="empty-box"
        v-if="smsList.length<=0"
      >
        <img
          src="../../assets/PersonalCenter/PointsCenter/noDataPng.png"
          alt
          width="80%"
        />
        <div class="empty">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAnnouncListApi, getMessageListApi } from '@/reuqest/api.js'
export default {
  computed: {
    ...mapGetters(['tenantId']),
  },
  data () {
    return {
      active: '',
      sysList: [],
      type: '',
      smsList: []
    }
  },
  async created () {
    this.type = this.$route.query.type
    const loadingToast = this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    //获取公告
    getAnnouncListApi({ ismore: 1, tenantId: this.tenantId }).then((res) => {
      this.sysList = res.data
      loadingToast.clear()
    }).catch(() => {
      loadingToast.clear()
    })
    //获取消息
    getMessageListApi({ ismore: 1, tenantId: this.tenantId }).then((res) => {
      this.smsList = res.data
      loadingToast.clear()
    }).catch(() => {
      loadingToast.clear()
    })
  },
}
</script>
 
<style lang="less" scoped>
.empty {
  font-size: 14px;
  color: #999;
}
.notice-list {
  background-color: #f5f5f5;
  padding: 10px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  .list-item {
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
    text-align: left;
    margin-bottom: 10px;

    .item-title {
      font-size: 18px;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .item-text {
      font-size: 16px;
      padding: 4px;
      line-height: 25px;
      span {
        color: #666;
      }
    }
    .item-btn {
      width: 100%;
      box-sizing: border-box;
      text-align: right;
      color: #21a4fe;
      font-size: 12px;
      padding: 8px;
    }
  }
}
.empty-list {
  height: 0;
  padding: 0;
}
.list-empty {
  padding-bottom: 15px;
  font-size: 14px;
  color: #999;
}
.ellipsis {
  // width: 95%;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
}
.info-content {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/deep/.info-content p {
  margin: 0 !important;
}
</style>